const DEFAULT_KEY = "856dee44-9343-43a0-9588-48c8a2faea4b"

//local
// const BASE_URL = "http://192.168.21.19:5022/node-api/";

//demo
// const BASE_URL = "https://admin.arjun.iossmlm.com/node-api/"

//live
const BASE_URL="https://admin.insurance.co.bw/node-api/"


export { BASE_URL, DEFAULT_KEY }